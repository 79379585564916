<template>
  <div>
    <search-field title="条件筛选">
      <el-form :inline="true" size="small" label-width="100px">
        <el-form-item label="现金流月份">
          <el-input clearable v-model="search.searchFields.month" placeholder="请输入现金流月份"></el-input>
        </el-form-item>
        <el-form-item label="类型">
          <el-select clearable v-model="search.searchFields.c_type" placeholder="请选择现金流类型">
            <el-option value="11" label="借款收入">借款收入</el-option>
            <el-option value="12" label="无票收入">无票收入</el-option>
            <el-option value="13" label="其他收入">其他收入</el-option>
            <el-option value="14" label="借款返还">借款返还</el-option>
            <el-option value="15" label="保证金">保证金</el-option>
            <el-option value="16" label="营销费">营销费</el-option>
            <el-option value="17" label="其他支出">其他支出</el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="search-button-container">
        <el-button type="primary" size="small" @click="getBudgetList(1)">查询</el-button>
        <el-button type="primary" size="small" @click="clearSearch()">重置</el-button>
      </div>
    </search-field>
    <div class="page-content">
      <div class="button-field">
        <el-button type="primary" size="small" @click="handleAdd()">添加</el-button>
        <el-button type="primary" size="small" @click="handleExportLedger()">下载现金流</el-button>
      </div>
      <v-table
          :data="options.data"
          :columns="options.columns"
          :isBackPage="true"
          :totalCount="options.totalCount"
          :defaultcurrentPage="search.searchFields.page"
          @handleCurrentChange="handlePageChange"
          @handleSizeChange="handleSizeChange"
          @buttonClick="handleButtonClick"
      >
      </v-table>
    </div>
    <el-dialog
        title="新增"
        width="25%"
        :visible.sync="exportLedgerLog"
    >
      <el-form size="small" label-width="110px">
        <el-form-item
            label="现金流年份"
            required
            :error="errorBags.collect('现金流年份')[0]"
        >
          <el-input
              v-model="budgetMonthForm.year"
              placeholder="请输入现金流年份"
              v-validate="'required'"
              data-vv-name="现金流年份"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="btn-container">
        <el-button @click="closeLedgerModal">取消</el-button>
        <el-button type="primary" @click="handleLedgerSubmit">确定</el-button>
      </div>
    </el-dialog>
    <el-dialog
        title="新增"
        width="25%"
        :visible.sync="shopAddDialog"
    >
      <el-form size="small" label-width="110px">
        <el-form-item
            label="现金流月份"
            required
            :error="errorBags.collect('现金流月份')[0]"
        >
          <el-input
              v-model="budgetForm.month"
              placeholder="请输入现金流月份"
              v-validate="'required'"
              data-vv-name="现金流月份"
          ></el-input>
        </el-form-item>
        <el-form-item
            label="现金流类型"
            required
            :error="errorBags.collect('现金流类型')[0]"
        >
          <el-select clearable v-model="budgetForm.c_type" placeholder="请选择现金流类型">
            <el-option value="11" label="借款收入">借款收入</el-option>
            <el-option value="12" label="无票收入">无票收入</el-option>
            <el-option value="13" label="其他收入">其他收入</el-option>
            <el-option value="14" label="借款返还">借款返还</el-option>
            <el-option value="15" label="保证金">保证金</el-option>
            <el-option value="16" label="营销费">营销费</el-option>
            <el-option value="17" label="其他支出">其他支出</el-option>
          </el-select>
        </el-form-item>
        <el-form-item
            label="现金流金额"
            required
            :error="errorBags.collect('现金流金额')[0]"
        >
          <el-input
              v-model="budgetForm.price"
              maxlength="12"
              placeholder="请输入现金流金额"
              v-validate="'required'"
              data-vv-name="现金流金额"
          ></el-input>
        </el-form-item>

        <el-form-item label="状态" required :error="errorBags.collect('状态')[0]">
          <el-select v-model="budgetForm.status" v-validate="'required'" data-vv-name="状态">
            <el-option
                v-for="item in statusOptions"
                :key="item.code"
                :label="item.value"
                :value="item.code"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <div class="btn-container">
          <el-button @click="closeModal">取消</el-button>
          <el-button type="primary" @click="handleSubmit">确定</el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>

import vTable from '@/components/tools/vtable'
import searchField from '@/components/tools/searchField'
import {BAApi} from '@/api'
import {formatDate} from '@/libs/utils'

export default {
  data() {
    return {
      options: {
        columns: [
          {
            label: '现金流月份',
            key: 'month'
          },
          {
            label: '现金流类型',
            key: 'c_type_msg'
          },
          {
            label: '现金流金额',
            key: 'price'
          },
          {
            label: '状态',
            key: 'status'
          },
          {
            label: '创建时间',
            type: 'render',
            render: (h, {row}) => {
              return (
                  <div>
                    <div class="status-td">
                      {
                        <div>{formatDate(Number(row.create_time) * 1000, 'YYYY-MM-DD HH:mm:ss').dateString}</div>
                      }
                    </div>
                  </div>
              )
            }
          },
          {
            label: '更新时间',
            type: 'render',
            render: (h, {row}) => {
              return (
                  <div>
                    <div class="status-td">
                      {
                        <div>{formatDate(Number(row.update_time) * 1000, 'YYYY-MM-DD HH:mm:ss').dateString}</div>
                      }
                    </div>
                  </div>
              )
            }
          },
          {
            label: '操作',
            type: 'action',
            selectButton: true,
            buttonInfos: [
              {
                name: 'edit',
                label: '编辑',
                color: 'primary'
              },
            ]
          }
        ],
        data: [],
        totalCount: 0,
        loading: false,
      },
      search: {
        searchFields: {
          page: 1,
          count: 20,
          month: '',
          c_type: '',
          type: 2,
        }
      },
      statusOptions: [
        {
          code: 1,
          value: '正常'
        }, {
          code: 0,
          value: '下架'
        },
      ],
      exportLedgerLog: false,
      shopAddDialog: false,
      budgetMonthForm: {
        month: '',
        type: 3,
        status: 1
      },
      budgetForm: {
        type: 2,
        month: '',
        c_type: '',
        price: '',
        status: 1
      },
      editBudgetId: '',
    }
  },

  methods: {
    // 后端分页
    handlePageChange(page) {
      this.search.searchFields.page = page
      this.getBudgetList()
    },
    handleSizeChange(count) {
      this.search.searchFields.count = count
      this.getBudgetList(1)
    },
    async getBudgetList(page) {
      let loading = this.$loading()
      this.search.searchFields.page = page || this.search.searchFields.page
      try {
        let data = (await BAApi.getLedgerCashList(this.search.searchFields)).data
        if (data.code === 0) {
          this.$validator.reset()
          this.options.data = data.data.map(item => {
            item.btnList = ['edit']
            return item
          })
          this.options.totalCount = parseInt(data.total_count) || 0
        } else {
          this.$error(data)
        }
      } catch (err) {
        console.error(err)
      } finally {
        loading.close()
      }
    },
    clearSearch() {
      this.search = {
        searchFields: Object.assign({count: this.search.searchFields.count}, {
          page: 1,
          month: '',
          c_type: '',
        })
      }
    },
    async handleButtonClick(value) {
      let type = value.button,
          data = value.data;
      this.editBudgetId = data.id;
      if (type === 'edit') {
        this.shopAddDialog = true;
        this.budgetForm.c_type = data.c_type;
        this.budgetForm.month = data.month;
        this.budgetForm.status = Number(data.status);
        this.budgetForm.price = data.price;
      }
    },
    handleExportLedger() {
      this.exportLedgerLog = true
    },
    closeLedgerModal() {
      this.resetSMForm()
      this.exportLedgerLog = false
    },
    handleAdd() {
      this.resetForm()
      this.$validator.reset()
      this.shopAddDialog = true
    },
    closeModal() {
      this.resetForm()
      this.shopAddDialog = false
    },
    handleLedgerSubmit() {
      this.$validator.validate().then(year => {
        if (year) {
          this.exportBudgetSM()
        } else {
          this.$message.error('请正确填写数据')
        }
      })
    },
    async exportBudgetSM() {
      let loading = this.$loading()
      try {
        let params = {
          ...this.budgetMonthForm,
          type: 3
        }
        let data = (await BAApi.exportBudget(params)).data
        if (data.code === 0) {
          loading.close()
          this.$validator.reset()
          this.resetSMForm()
          this.exportLedgerLog = false
          let time = formatDate(new Date(), 'YYYY-MM-DD HH:mm:ss').dateString
          let downLink = document.createElement('a')
          downLink.href = data.data.file_path
          downLink.download = `${time}-预算年度列表`
          downLink.click()
        } else {
          loading.close()
          this.$error(data)
        }
      } catch (error) {
        this.$validator.reset()
        loading.close()
      }
    },
    handleSubmit() {
      this.$validator.validate().then(status => {
        if (status) {
          this.addBudget()
        } else {
          this.$message.error('请正确填写数据')
        }
      })
    },
    async addBudget() {
      let loading = this.$loading()
      try {
        let params = {
          ...this.budgetForm,
          id: this.editBudgetId,
        }
        let data = (await BAApi.addOrEditLC(params)).data
        if (data.code === 0) {
          loading.close()
          this.$validator.reset()
          this.$message.success('添加现金流成功')
          this.resetForm()
          this.shopAddDialog = false
          this.getBudgetList()
        } else {
          loading.close()
          this.$error(data)
        }
      } catch (error) {
        this.$validator.reset()
        loading.close()
      }
    },

    resetForm() {
      let obj = {
        month: '',
        c_type: '',
        price: '',
        type: 2
      }
      this.budgetForm = obj
      this.editBudgetId = '';
    },
    resetSMForm() {
      let obj = {
        year: ''
      }
      this.budgetMonthForm = obj
    }
  },

  mounted() {
    this.getBudgetList()
  },
  components: {
    vTable, searchField
  }
}
</script>

<style lang="less">
.status-td {
  display: flex;
  align-items: center;
  justify-content: center;

  .tag {
    width: 12px;
    height: 12px;
    display: inline-block;
    border-radius: 50%;
    margin-right: 6px;
  }

  .tag2 {
    background-color: #67C23A;
  }

  .tag3 {
    background-color: #F56C6C;
  }
}
</style>

